html,
body,
#root
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #141c21;
}

.app-main
{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.app-content
{
  height: 100%;
}
